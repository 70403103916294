
// Libraries
import * as React from 'react'

// Services
import BlogService from '../../../services/blog'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import PrivateRoute from '../../../components/privateRoute'
import withLocation from '../../../components/withLocation'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminBlogViewRow from '../../../components/admin/blog/adminBlogViewRow'
import InputFactory from '../../../components/inputFactory'
import Button from '../../../components/button'
import DuoSection from '../../../components/duoSection'
import AdminEventViewRow from '../../../components/admin/events/adminEventViewRow'
import AdminBlogConfirmPublish from '../../../components/admin/blog/adminBlogConfirmPublish'
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AdminBlogNewPage extends React.Component {
	state = {
		initialValues: {},
		inputValues: {},
		inputErrors: {},
		isSubmitting: false,
		modalConfirmPublish: false,
		confirmedPublish: false,
	}

	static contextType = NotificationContext

	render() {
		const {initialValues, inputValues, inputErrors, isSubmitting, modalConfirmPublish} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-form nav-blue-half" hideSideNav>
				<Seo title="New Blog" />
				<DuoSection adminForm>
					<div>
						<h1>New Blog</h1>
					</div>
					<div className="admin-form__wrap">
						<div className="admin-form admin-form--blog">
							<div className="admin-form__form">
								<form onSubmit={this.handleSubmit}>
									<div className="admin-form__field-group">
										<AdminBlogViewRow
											label="Title*"
											value={<InputFactory
												type="text"
												name="title"
												value={inputValues?.title}
												error={inputErrors?.title}
												onChange={this.handleInputChange}
											/>} />
										<AdminBlogViewRow
											label="Teaser Text*"
											value={<InputFactory
												type="text"
												name="summary"
												value={inputValues?.summary}
												error={inputErrors?.summary}
												onChange={this.handleInputChange}
											/>} />
										<AdminBlogViewRow
											label="Content*"
											value={<InputFactory
												type="tinymce"
												name="content"
												initialValue={initialValues?.content}
												value={inputValues?.content}
												error={inputErrors?.content}
												onChange={this.handleInputChange}
												config="blog"
											/>} />
										<AdminEventViewRow
											label={<>
												Publish Date* <FontAwesomeIcon
													icon={faInfoCircle}
													data-tip data-for='tooltip-date'
													className='tooltip-icon'
													multiline={true}
													html={true} />
												<ReactTooltip id='tooltip-date' place="top" backgroundColor="#005EB8" textColor="#fff">
													If date is today or earlier, post will be published,
													else it will be drafted & published on the date supplied
												</ReactTooltip>
											</>}
											value={<InputFactory
												type="date"
												name="publish_at_date"
												value={inputValues?.publish_at_date}
												error={inputErrors?.publish_at_date}
												onChange={this.handleInputChange}
											/>} />
										<AdminBlogViewRow
											label="Feature Image*"
											value={<InputFactory
												type="file"
												name="image"
												value={inputValues?.image}
												initialFilePreview={inputValues?.imagePreview || null}
												error={inputErrors?.image}
												onChange={this.handleInputChange}
											/>} />
									</div>

									<div className="admin-form__submit-wrap">
										<Button type="button" to="/admin/blog" colorEndeavour hollow>Cancel</Button>
										<Button type="submit" isLoading={isSubmitting} colorEndeavour>Add Blog</Button>
									</div>
								</form>
							</div>

							{modalConfirmPublish && <AdminBlogConfirmPublish
								handleClose={() => this.setState({modalConfirmPublish: false})}
								handleConfirm={this.handleConfirmPublish} />}
						</div>
					</div>
				</DuoSection>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {initialValues, inputValues} = this.state

		initialValues.content = ''

		this.setState({ inputValues })
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) { // Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else { // Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleConfirmPublish = () => {
		this.setState({
			modalConfirmPublish: false,
			confirmedPublish: true
		}, () => {
			this.handleSubmit()
		})
	}

	handleSubmit = async (submitEvent) => {
		if (submitEvent) {
			submitEvent.preventDefault()
		}
		this.setState({
			isSubmitting: true
		})

		const {inputValues, confirmedPublish} = this.state
		const blogService = new BlogService()
		const {addNotification} = this.context

		const publish_at = moment(inputValues.publish_at_date)

		// confirm date change
		if (publish_at <= moment() && !confirmedPublish) {
			this.setState({
				modalConfirmPublish: true,
				isSubmitting: false
			})
			return false
		} else {
			this.setState({
				confirmedPublish: false
			})
		}

		const formData = blogService.formData(inputValues)
		await blogService.post(formData)
			.then(async (response) => {
				if (response.success) {
					addNotification('New Blog Created', 'success')
					this.props.navigate('/admin/blog')
				}
				else {
					const inputErrors = response.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default withLocation(AdminBlogNewPage)
