
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class BlogService {
	async list(queryParams = {}) {
		return await apiService.get(`blog`, {}, queryParams)
	}

	async get(idOrSlug = null) {
		return await apiService.get(`blog/${idOrSlug}`)
	}

	async post(formData = {}) {
		return await apiService.post('blog', {body: formData}, true)
	}

	async put(id, formData = {}) {
		return await apiService.put(`blog/${id}`, {body: formData}, true)
	}

	async delete(id = null) {
		return await apiService.delete(`blog/${id}`)
	}

	formData(inputValues) {
		const formData = new FormData()

		if (inputValues['publish_at_date']) {
			inputValues['publish_at'] = inputValues['publish_at_date']
		}

		Object.keys(inputValues).forEach(key => {
			formData.set(key, inputValues[key])
		})

		return formData
	}
}
