
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/pro-regular-svg-icons'

class Modal extends React.Component {
	render() {
		const {handleClose, children} = this.props
		let classes = Classnames([
			'modal',
			{
				'modal--confirm': this.props.modalConfirm,
			},
			{
				'modal--right': this.props.modalRight,
			},
			{
				'modal--admin-form': this.props.adminForm,
			},
			{
				'modal--preview': this.props.modalPreview,
			},
			{
				'modal--assign-winners': this.props.assignWinners,
			},
		]) 

		return <div className="modal__overlay">
			<div className={classes}>
				{handleClose && <button href="#" onClick={handleClose} className="modal__close-link">
					<FontAwesomeIcon icon={faTimes} title="Close icon" />
					<span> Close</span>
				</button>}
				{children}
			</div>
		</div>
	}
}

export default Modal
